
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";






.clear{
  clear: both;
  margin-bottom: 10px;
}

.animation-container{
  position: relative !important;
  left: 100%;
  top: -30px;
  width: 200px;
  height: 0;

}

.sync-fields-table .animation-container{
  left: 80%;
}

.sync-fields-table .select2{
  width: 80% !important;
}

.animation{
  position: absolute !important;
  white-space: nowrap;
  left: 1px;
  top: -1px;

}

.green{
  color: green;
}

.delete-sync-field{
  float: right;
  padding: 2px 8px;
  display: none;
}

.delete-sync-field:hover .delete-sync-field-icon{
  color: red;
}

tr:hover .delete-sync-field{
  display: inline-block;
}

.btn-sync{
  padding: 3px 9px;
  margin-top: -5px;
}

.panel-heading a{
  color: #fff;
}

#sync_test_container .panel-body{
  min-height: 125px;
}

.sync-direction-icon{
  cursor: pointer;
}

.sync-direction-icon:hover{
  text-decoration: underline;
}

.display-tooltip{
  opacity: 0;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  -ms-transition: all .5s ease-out;
  transition: all .5s ease-out;
}

.panel-heading:hover .display-tooltip, .panel-body:hover .display-tooltip{
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  opacity: 1;
}

.btn-help-video{
  padding: 2px 8px;
  opacity: 0;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  -ms-transition: all .5s ease-out;
  transition: all .5s ease-out;
}

.panel:hover .btn-help-video{
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  opacity: 1;
}